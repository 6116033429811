import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';


// import logo from './logo.svg';
import './App.css';

const App = () => (
  <React.Fragment>
      <section className="has-bg-image">
      <Header />
      <Outlet></Outlet>
      <Footer />
    </section>
    </React.Fragment>
);

// const Main = () => (
//   <React.Fragment>
//     <Header />
//     <Route exact path="/" component={Home} />
//     <Route exact path="/schedule" component={Schedule} />
//     <Route path="/hotel" component={Venue} />
//     <Route path="/auction" component={Auction} />
//     <Route path="/about" component={About} />
//     <Route path="/contact" component={Contact} />
//     <Route path="/copyright" component={Copyright} />
//     <Route path="/privacy" component={Privacy} />
//     <Footer />
//   </React.Fragment>
// )

export default App;
