import React from 'react';

const Modal = (props) => {

  if(!props.modalState){
    return null;
  }
  return (
  <div className="modal is-active">
    <div onClick={props.closeModal} className="modal-background"></div>
    <div className="modal-content">
      {props.type === 'img' && (
        <p className="image">
          <img alt="modal" src={props.content} />
        </p>
      )}
      {props.type === 'leaving' && (
        <React.Fragment>
          <section className="modal-card-body">
            You are now leaving the Cocaine Anonymous New York Website. <br />
            POLICY ON EXTERNAL LINKS: We are not responsible for a canewyork.org link to a third-party's website privacy policy, 
            tracking technology, computer security, or intellectual property protection. We cannot attest to the accuracy, relevancy, 
            timeliness, or completeness of information provided by any linked site. Linking to another website does not constitute an endorsement 
            by C.A. New York.
          </section>
          <footer className="modal-card-foot">
            <a href={props.content} className="button is-success">Continue</a>
            <button onClick={props.closeModal} className="button">Cancel</button>
          </footer>
        </React.Fragment>
      )}
    </div>
    <button onClick={props.closeModal} className="modal-close is-large" aria-label="close"></button>
  </div>
  );
}

export default Modal;