import React from "react";

class Venue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false
    };
  }

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img
    });
    this._toggleModal();
  };

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState };
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <h1 className="title"> Hotel </h1>
            <div className="columns">
              <div className="column">
                <div className="box has-background-white content">
                  <p className="subtitle">Coming Soon</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Venue;
