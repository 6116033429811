import React from "react";

class Fundraising extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <h1 className="title"> Fundraising Events </h1>
            <div className="columns">
              <div className="column">
                <div className="box has-background-white content">
                  <p className="subtitle">Coming Soon</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Fundraising;
