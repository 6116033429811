import React from 'react';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state = {
     selected: false
    }
  }
  _toggleNav = (e) => {
    e.preventDefault();
    this.setState({
      selected: !this.state.selected
    })
  }

  _toggleNavOff = (e) => {
    this.setState({
      selected: false
    })
  }

  render() {
    return (
      <React.Fragment>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
          <div className="navbar-item">
            <Link onClick={(e) => this._toggleNavOff(e)} className="navbar-item" to="/"><img src="/img/anrc-logo.jpg" alt="ANRC" height="20px" /></Link>
          </div>
            <a href="0" onClick={(e) => this._toggleNav(e)} role="button" className={this.state.selected === false ? 'navbar-burger burger' : 'is-active navbar-burger burger'} aria-label="menu" aria-expanded="false" data-target="navbarMain">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarMain" className={this.state.selected === false ? 'navbar-menu' : 'is-active navbar-menu'}>
            <div className="navbar-start">
              <Link onClick={(e) => this._toggleNavOff(e)} className="navbar-item" to="/">Home</Link>
              <Link onClick={(e) => this._toggleNavOff(e)} className="navbar-item" to="/contact">Committees & Service</Link>
            </div>

            <div className="navbar-end">
                <div className="navbar-item">
                  <img src="/img/ca-logo.png" alt="CA" />
                </div>
            </div>

          </div>
        </nav>
      </React.Fragment>
    )
  }
}

export default Header;
