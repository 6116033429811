import React from "react";
import Modal from "../Modal/Modal";

import "./Home.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="title">2023 ATLANTIC NORTH REGIONAL CONVENTION
                </p>
                <p className="subtitle">Toronto October 13 – 15, 2023</p>
                  <figure className="image is-3by3">
                    <img alt="" src="/img/image0.jpeg"></img>
                  </figure>
                  <p>
                    <br></br>
                    Join us in the beautiful city of Toronto, Canada for what promises to be an incredible weekend packed with amazing recovery speakers, fellowship, entertainment, dinner and the world famous runway sobriety countdown and DJ dance party!
                    <br></br>
                    <br></br>
                    “…this is an experience you must not miss.”
                    <br></br><br></br>
                    Venue: BMO IFL<br></br>
                    3550 Pharmacy Ave, Scarborough, ON M1W 3Z3<br></br>
                    TORONTO, ON, CANADA
                    </p><p>
                  </p>

                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <figure className="image is-3by3">
                  <img alt="" src="/img/image1.jpeg"></img>
                </figure>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Registration</p>
                  <p>
                    Register now for the upcoming ANRC 2023 in Toronto on October 13 - 15 
                    <br></br>
                    <br></br>
                    <a target="_blank" rel="noreferrer" href="https://anrc2023.regfox.com/atlantic-north-regional-convention-2023">Registration Site</a>
                    <br></br>
                    <br></br>
                    <i>Full Weekend Package ($140.00)</i>
                    <br></br>
                    Includes general admission for Friday, Saturday and Sunday, banquet dinner of Saturday evening and brunch on Sunday morning
                    <br></br>
                    <br></br>
                    <i>General Weekend Admission ($40.00)</i>
                  <br></br>
                  General admission for Friday, Saturday and Sunday
                  </p>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Hotel</p>
                  <p>
                  Courtyard Toronto <br></br>
                  Northeast/Markham <br></br>
                  7095 Woodbine Avenue Markham, Ontario L3R 1A3 <br></br>
                    <br></br>
                    <a target="_blank" rel="noreferrer" href="https://www.marriott.com/events/start.mi?id=1689000816617&key=GRP">Hotel Reservation</a>
                  </p>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="subtitle">Program </p>
                  <figure className="image is-3by3">
                    <img alt="" src="/img/1000018695.jpg"></img>
                  </figure>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="subtitle">ANRC memorabilia available now for purchase! Lots of great items including tees, hats, and hoodies! Get some great gear and support our fellowship and upcoming ANRC! </p>
                  <figure className="image is-3by3">
                    <a target="_blank" rel="noreferrer" href="https://forms.gle/9S5PCyFUVAwDzSRV6">
                    <img alt="" src="/img/anrc-memorabilia.png"></img></a>
                  </figure>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
              <article className="tile is-child box">
                  <figure className="image is-3by3">
                    <img alt="" src="/img/1000018639.jpg"></img>
                  </figure>
                </article>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h1 className="subtitle"> Preamble </h1>
            <h2>
              Cocaine Anonymous is a fellowship of men and women who share their
              experience, strength and hope with each other that they may solve
              their common problem and help others to recover from their
              addiction.The only requirement for membership is a desire to stop
              using cocaine and all other mind‐ altering substances.There are no
              dues or fees for membership; we are fully self‐ supporting through
              our own contributions.We are not allied with any sect,
              denomination, politics, organization or institution.We do not wish
              to engage in any controversy, and we neither endorse nor oppose
              any causes. Our primary purpose is to stay free from cocaine and
              all other mind‐ altering substances and to help others achieve the
              same freedom. We use the Twelve‐ Step recovery Program because it
              has already been proven that the Twelve‐ Step Recovery Program
              works. Adapted with permission from A.A.Grapevine Inc.
            </h2>
          </div>
        </section>
        <Modal
          type={this.state.modalType}
          content={this.state.modalContent}
          closeModal={this._toggleModal}
          modalState={this.state.modalShow}
        />
      </React.Fragment>
    );
  }
}

export default Home;
