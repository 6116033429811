import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './components/Home/Home';
import Schedule from './components/Schedule/Schedule';
import Venue from './components/Venue/Venue';
import Auction from './components/Auction/Auction';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Copyright from './components/Copyright/Copyright';
import Privacy from './components/Privacy/Privacy';
import Fundraising from './components/Fundraising/Fundraising';
// import AppRouter from './AppRouter';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="schedule" element={<Schedule/>} />
          <Route path="hotel" element={<Venue/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="copyright" element={<Copyright/>} />
          <Route path="privacy" element={<Privacy/>} />
          <Route path="auction" element={<Auction/>} />
          <Route path="fundraising" element={<Fundraising/>} />
          <Route path="about" element={<About/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
